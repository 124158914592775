import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { AddEmployeeDocument, Addborrower, Deletedocument, GetEmployeeDetail, getEmployeeDocument, postVerification } from '../service/lenderService';
import { Image } from 'antd';


const AddewaAdvance = () => {
    const [data, setData] = useState({
        mobile_number: "",
        // employer_id: "",
        // employer_id_true: false,
        require_loan_amount: "",
        require_loan_amount_true: false,
        loan_type: "",
        loan_type_true: false,
        tin: "",
        tin_true: false,
        fnpf: "",
        fnpf_true: false,
        select: "",
        select_true: false,
        first_name: "",
        first_name_true: false,
        middle_name: "",
        middle_name_true: false,
        last_name: "",
        last_name_true: false,
        account_name: "",
        account_name_true: false,
        account_number: "",
        account_number_true: false,
        bank_name: "",
        bank_name_true: false,
        branch: "",
        branch_true: false,
        personal_email: "",
        personal_email_true: false,
        work_email: "",
        work_email_true: false,
        residental_address: "",
        residental_address_true: false,
        postal_address: "",
        postal_address_true: false,
        period: "",
        period_true: false,
        postal_period: "",
        postal_period_true: false,
        previus_address: "",
        previus_address_true: false,
        previous_period: "",
        previous_period_true: false,
        instagram: "",
        instagram_true: false,
        facebook: "",
        facebook_true: false,
        linkedin: "",
        linkedin_true: false,

        telephone_home: "",
        telephone_home_true: false,
        telephone_work: "",
        telephone_work_true: false,
        date_of_birth: "",
        date_of_birth_true: false,
        martial_status: "",
        martial_status_true: false,
        dependent_no: '',
        dependent_no_true: false,
        occupation: "",
        occupation_true: false,
        current_office_address: '',
        current_office_address_true: false,
        current_employer: "",
        current_employer_true: false,
        employed_since: "",
        employed_since_true: false,

        //3
        previus_employer: "",
        previus_employer_true: false,
        previus_office_address: "",
        previus_office_address_true: false,
        previous_employer_period: "",
        previous_employer_period_true: false,
        house_est_value: "",
        house_est_value_true: false,
        mortgaged: "",
        mortgaged_true: false,
        monthly_payment: "",
        monthly_payment_true: false,
        notliving_house: "",
        notliving_house_true: false,
        mortgage_balance: "",
        mortgage_balance_true: false,

        //4

        annual_salary: "",
        annual_salary_true: false,
        monthly_income: "",
        monthly_income_true: false,
        other_income: "",
        other_income_true: false,
        pay_cycle: "",
        pay_cycle_true: false,

        //5
        house_value: "",
        living_house: "",
        living_house_true: false,
        car_value: "",
        car_value_true: false,
        land_value: "",
        land_value_true: false,
        life_policy: "",
        life_policy_true: false,
        debtors: "",
        debtors_true: false,
        personal_effects: "",
        personal_effects_true: false,
        cash_at_bank: "",
        cash_at_bank_true: false,
        cash_on_hand: "",
        cash_on_hand_true: false,


        //6
        mortgage_loan_balnce: "",
        mortgage_loan_balnce_true: false,
        life_insurance: "",
        life_insurance_true: false,
        home_loan: "",
        home_loan_true: false,
        otherloan_balance: "",
        otherloan_balance_true: false,
        // liabilites: "",
        // liabilites_true: false,
        total_current_deduction: "",
        total_current_deduction_true: false,


        //7
        rent: "",
        rent_true: false,
        electricity: "",
        electricity_true: false,
        bill_for_phone: "",
        bill_for_phone_true: false,
        bill_for_water: "",
        bill_for_water_true: false,
        house_keeping: "",
        house_keeping_true: false,
        school_fees: "",
        school_fees_true: false,
        travelling: "",
        travelling_true: false,
        entertainment: "",
        entertainment_true: false,
        hire_payments: "",
        hire_payments_true: false,

        //8
        disclaimer_q1: 0,
        disclaimer_q1_true: false,
        disclaimer_q2: 0,
        disclaimer_q2_true: false,
        purpose_of_ewa_advance: "",
        purpose_of_ewa_advance_true: false,
        monthly_business_income: "",
        monthly_business_income_true: false,
        is_consent1: 0,
        is_consent1_true: "",
        is_consent: 0,
        is_consent_true: "",
        disclaimer_3_true: false,
        SIGNATURE: false,
        SIGNATURE_IMAGE: [],
        PAYSLIPS: false,
        PAYSLIPS_IMAGE: [],
        PHOTO_ID: false,
        PHOTO_ID_IMAGE: [],
        EMPLOYMENT_LETTER: false,
        EMPLOYMENT_LETTER_IMAGE: [],
        TIN_LETTER: false,
        TIN_LETTER_IMAGE: [],
        LOAN_STATEMENTS: false,
        LOAN_STATEMENTS_IMAGE: [],
        BANK_STATEMENTS: false,
        BANK_STATEMENTS_IMAGE: [],
        INCOME: false,
        INCOME_IMAGE: [],
        finalSubmit: 0
    })
    let steps = [];
    const [currentStep, setCurrentStep] = useState(0)

    const handleNextStep = (newData, final = false) => {

        setData((prev) => ({ ...prev, ...newData }));
        setCurrentStep(currentStep + 1)

    };
    const handlePrevStep = (newData) => {

        setData((prev) => ({ ...prev, ...newData }));
        setCurrentStep((prev) => prev - 1);
    };



    steps.push(<StepOne /* GetStates={GetStates} */ /* setHeader={setHeader} header={header} */ next={handleNextStep} prev={handlePrevStep} setData={setData} data={data} setCurrentStep={setCurrentStep} /* allStates={allStates} */ />)



    return (
        <>
            {steps[currentStep]}
        </>

    )
}

export default AddewaAdvance


const StepOne = (props) => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [user_loan_id, setuser_loan_id] = useState()
    // const [check, setcheck] = useState()
    // const [signature, setSignature] = useState("SIGNATURE")
    // const [payslip, setpayslip] = useState("PAYSLIPS")

    // const [nextcheck, setnextcheck] = useState(false)
    // const [imageUpload, setimageUpload] = useState(null)
    const lender = JSON.parse(localStorage.getItem("lender"));



    // const Verification = async () => {
    //     const data = JSON.stringify({
    //         mobile_number: AddForm.getFieldProps('mobile_number').value,
    //     })
    //     const response = await postVerification(lender.lender_token, data)
    //     if (response.status == true) {
    //         toast.success(response.message)
    //         setuser_loan_id(response.data.user_loan_id)
    //         setCurrentStep(currentStep + 1)
    //     } else if (response.status == false) {
    //         toast.error(response.message)
    //     }
    // }

    // const [logoValue, setDocValue] = useState({})
    // const [logoValue1, setDocValue1] = useState({})


    // const uploadLogoFunc = async (e) => {
    //     const file = e.target.files[0];
    //     setDocValue(file) || setDocValue1(file)
    //     addSettlementForm.setValues({
    //         ...addSettlementForm.values,
    //         imageUpload: file
    //     })
    // }

    const updateEmployeeData = async (e) => {
        if (e.target.name == 'disclaimer_q1' || e.target.name == 'disclaimer_q2' || e.target.name == 'is_consent' || e.target.name == 'is_consent1') {
            AddForm.setFieldValue(e.target.name, e.target.value)
        }
        await Addborrower(lender.lender_token, { [e.target.name]: e.target.value, user_loan_id: user_loan_id }).then((res) => {
            if (res.status == true) {
                if (e.target.value.length !== 0) {
                    AddForm.setFieldValue(e.target.name + "_true", true)
                }
                else {
                    AddForm.setFieldValue(e.target.name + "_true", false)
                }
            } else {

            }
        })
        // }

    }

    const uploadImage = async (e, type = '') => {
        const file = e.target.files[0];
        const acceptedFileTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
        if (file) {
            if (!acceptedFileTypes.includes(file.type)) {
                toast.error('Only PDF, JPEG, JPG, and PNG files are accepted');
                e.target.value = '';
                return;
            }

            const formData = new FormData();
            formData.append("user_loan_id", user_loan_id);
            formData.append("doc_type", type);
            formData.append("image", file);

            AddEmployeeDocument(lender.lender_token, formData).then((res) => {
                if (res.status === true) {
                    toast.success(res.message);
                    AddForm.setFieldValue(type, true);
                    getImage(type);
                } else {
                    toast.error(res.message);
                }
            });
        }
    };

    // const uploadImage = async (e, type = '') => {
    //     const file = e.target.files[0];
    //     const formData = new FormData();
    //     formData.append("user_loan_id", user_loan_id);
    //     formData.append("doc_type", type);
    //     formData.append("image", file);
    //     AddEmployeeDocument(lender.lender_token, formData).then((res) => {
    //         if (res.status == true) {
    //             toast.success(res.message);
    //             AddForm.setFieldValue(type, true)
    //             getImage(type)
    //         } else {
    //             toast.error(res.message);
    //         }
    //     })
    // }

    const getImage = async (type = '', static_user_loan_id = 0) => {
        getEmployeeDocument(lender.lender_token, { user_loan_id: static_user_loan_id > 0 ? static_user_loan_id : user_loan_id, doc_type: type }).then(async (res) => {
            if (res.status == 'success') {
                props.data[type + '_IMAGE'] = res.data;
                AddForm.setFieldValue(type, res.data.length ? true : false)
                await props.setData(props.data)
                await AddForm.validateForm();
            } else {

            }
        })
    }

    // const updateDisclaimer = async (e, field = '') => {
    //     if (field) {
    //         AddForm.setFieldValue(field, e.target.value)
    //     }
    // }

    // const addSettlementForm = useFormik({
    //     initialValues: {
    //         user_loan_id: user_loan_id,
    //         image: imageUpload,
    //         doc_type: ""
    //     },
    //     enableReinitialize: true,
    //     validationSchema: yup.object({
    //         //   comment: yup.string().required('Please add a comment'),
    //         // imageUpload: currentStep == 8 ? yup.string().required('Please Select Image '): "",

    //     }),
    //     onSubmit: async values => {
    //         const formData = new FormData();
    //         formData.append("user_loan_id", user_loan_id);
    //         formData.append("doc_type", signature);
    //         formData.append("image", imageUpload);
    //         AddEmployeeDocument(lender.lender_token, formData).then((res) => {
    //             // console.log(res)
    //             if (res.status == true) {
    //                 toast.success(res.message);
    //                 setCurrentStep(currentStep + 1)
    //             } else {
    //                 toast.error(res.message)
    //             }
    //         }).catch((err) => {
    //             console.log(err)

    //         })
    //     }
    // });

    const AddForm = useFormik({
        initialValues: props.data,
        enableReinitialize: true,
        validationSchema: yup.object({
            mobile_number: currentStep == 0 ? yup.string().required('Please Enter Mobile Number').min(7, `Mobile Number Should be at least 7 Digit Long`).max(15, `Mobile Number Can't Be 15 Digit Long`) : "",
            // employer_id: currentStep == 1 ? yup.string().required('Please Select Loan Type') : "",
            require_loan_amount: currentStep == 1 ? yup.string().required('Please Enter Required Amount') : "",
            loan_type: currentStep == 1 ? yup.string().required('Please Select EWA Advance Type') : "",
            tin: currentStep == 1 ? yup.string().required('Please Enter TIN ').max(7, `TIN max upto 7 characters`) : "",
            fnpf: currentStep == 1 ? yup.string().required('Please Enter FNPF').max(7, `FNPF max upto 7 characters`) : "",
            // select: currentStep == 1 ? yup.string().required('Please Select Name Prefix') : "",
            first_name: currentStep == 1 ? yup.string().required('Please Enter First Name') : "",
            middle_name: currentStep == 1 ? yup.string().required('Please Enter Middle Name') : "",
            last_name: currentStep == 1 ? yup.string().required('Please Enter Last Name') : "",
            residental_address: currentStep == 1 ? yup.string().required('Please Enter Residential Address') : "",

            postal_address: currentStep == 1 ? yup.string().required('Please Enter Postal Address') : "",
            previus_address: currentStep == 1 ? yup.string().required('Please Enter Previous Address') : "",

            period: currentStep == 1 ? yup.string().required('Please select Residential Period') : "",
            postal_period: currentStep == 1 ? yup.string().required('Please select Postal Period') : "",
            previous_period: currentStep == 1 ? yup.string().required('Please select Previous Period') : "",
            account_name: currentStep == 1 ? yup.string().required('Please Enter Account Name') : "",
            account_number: currentStep == 1 ? yup.string().required('Please Enter Account Number').min(7, `Account Number Should be at least 7 Digit Long`).max(18, `Account Number Can't Be 18 Digit Long`) : "",
            bank_name: currentStep == 1 ? yup.string().required('Please Enter Bank Name') : "",
            branch: currentStep == 1 ? yup.string().required('Please Enter Branch Name') : "",

            personal_email: currentStep == 2 ? yup.string().email("Invalid Personal Email Address Format").required('Please Enter Personal Email Address') : "",
            work_email: currentStep == 2 ? yup.string().email("Invalid Work Email Address Format").required('Please Enter Work Email Address') : "",
            telephone_home: currentStep == 2 ? yup.string()/* .typeError('Please enter a valid mobile number') */.required('Please Enter Mobile No.').min(7, 'Mobile number must contain 7 number').max(15, 'Mobile number max contain 15 number') : "",
            telephone_work: currentStep == 2 ? yup.string().required('Please Enter Work Telephone No. ').min(7, 'Telephone number must contain 7 number').max(15, 'Telephone number max contain 15 number') : ""/* .matches(regex, 'Please Enter Only Character Values') */,
            date_of_birth: currentStep == 2 ? yup.string().required('Please select DOB') : "",
            martial_status: currentStep == 2 ? yup.string().required('Please Select Martial Status') : '',
            // dependent_no: currentStep == 2 ? yup.string().required('Please Enter No Of Dependent').max(1, 'Invalid Number') : '',
            occupation: currentStep == 2 ? yup.string().required('Please Enter Occupation') : "",
            current_employer: currentStep == 2 ? yup.string().required('Please Enter Current Employer') : "",
            current_office_address: currentStep == 2 ? yup.string().required('Please Enter Current Employer Address') : "",
            employed_since: currentStep == 2 ? yup.string().required('Please select employed Period') : "",


            previus_employer: currentStep == 3 ? yup.string().required('Please Enter Previous Employer') : "",
            previus_office_address: currentStep == 3 ? yup.string().required('Please Enter Previous Employer Address') : "",
            previous_employer_period: currentStep == 3 ? yup.string().required('Please Enter Previous Employer Period') : "",
            house_est_value: currentStep == 3 ? yup.string().required('Please Enter House Estimated Value') : "",
            mortgaged: currentStep == 3 ? yup.string().required('Please Enter Mortgaged Bank Name') : "",
            monthly_payment: currentStep == 3 ? yup.string().required('Please Enter Monthly Repayment ').max(7, `Monthly Repayment max upto 7 characters`) : "",
            notliving_house: currentStep == 3 ? yup.string().required('Please select Living House Status ') : "",
            mortgage_balance: currentStep == 3 ? yup.string().required('Please Enter Remaining Mortgage Balance') : "",

            pay_cycle: currentStep == 4 ? yup.string().required('Please select Pay Cycle ') : "",
            annual_salary: currentStep == 4 ? yup.string().required('Please Enter Annual Income ') : "",
            other_income: currentStep == 4 ? yup.string().required('Please Enter Other Income ') : "",
            // monthly_income: currentStep == 4 ? yup.string().required('Please Enter Monthly Income ') : "",


            house_value: currentStep == 5 ? yup.string().required('Please Enter House Value ') : "",
            car_value: currentStep == 5 ? yup.string().required('Please Enter Car Value ') : "",
            land_value: currentStep == 5 ? yup.string().required('Please Enter Land Value ') : "",
            life_policy: currentStep == 5 ? yup.string().required('Please Enter Life Policies ') : "",
            debtors: currentStep == 5 ? yup.string().required('Please Enter Debtors ') : "",
            personal_effects: currentStep == 5 ? yup.string().required('Please Enter Personal Effects ') : "",
            cash_on_hand: currentStep == 5 ? yup.string().required('Please Enter Cash in Hand ') : "",
            cash_at_bank: currentStep == 5 ? yup.string().required('Please Enter Cash At Bank ') : "",



            mortgage_loan_balnce: currentStep == 6 ? yup.string().required('Please Enter Mortgage Loan Balance ') : "",
            home_loan: currentStep == 6 ? yup.string().required('Please Enter Home Loan ') : "",
            otherloan_balance: currentStep == 6 ? yup.string().required('Please Enter Other Loan Payment ') : "",
            // liabilites: currentStep == 6 ? yup.string().required('Please Enter Total Liabilities ') : "",
            total_current_deduction: currentStep == 6 ? yup.string().required('Please Enter Total Current Deduction ') : "",

            rent: currentStep == 7 ? yup.string().required('Please Enter Rent ') : "",
            electricity: currentStep == 7 ? yup.string().required('Please Enter Electricity ') : "",
            bill_for_phone: currentStep == 7 ? yup.string().required('Please Enter Telephone Cost ') : "",
            bill_for_water: currentStep == 7 ? yup.string().required('Please Enter Water ') : "",
            house_keeping: currentStep == 7 ? yup.string().required('Please Enter House Keeping ') : "",
            school_fees: currentStep == 7 ? yup.string().required('Please Enter School_Fees ') : "",
            travelling: currentStep == 7 ? yup.string().required('Please Enter Travelling ') : "",
            entertainment: currentStep == 7 ? yup.string().required('Please Enter Entertainment ') : "",
            hire_payments: currentStep == 7 ? yup.string().required('Please Total Hire Purchase Payments ') : "",
            life_insurance: currentStep == 7 ? yup.string().required('Please Enter Life Insurance ') : "",

            disclaimer_q1: currentStep == 8 ? yup.string().required('Field must be checked') : "",
            disclaimer_q2: currentStep == 8 ? yup.string().required('Field must be checked') : "",
            purpose_of_ewa_advance: currentStep == 8 ? yup.string().required('Please Select Purpose of EWA Advance') : '',
            monthly_business_income: currentStep == 8 ? yup.string().required('Please Enter Monthly Business Income') : '',

            is_consent1: currentStep == 8 ? yup.string().required('Field must be checked') : "",
            is_consent: currentStep == 8 ? yup.string().required('Field must be checked') : "",
            SIGNATURE: currentStep == 8 ? yup.bool().oneOf([true], 'Image is required') : "",

            PAYSLIPS: currentStep == 9 ? yup.bool().oneOf([true], 'Document is required') : "",
            // PHOTO_ID: currentStep == 9 ? yup.bool().oneOf([true], 'Image is required') : "",
            // EMPLOYMENT_LETTER: currentStep == 9 ? yup.bool().oneOf([true], 'Image is required') : "",
            // TIN_LETTER: currentStep == 9 ? yup.bool().oneOf([true], 'Image is required') : "",
            // LOAN_STATEMENTS: currentStep == 9 ? yup.bool().oneOf([true], 'Document is required') : "",
            BANK_STATEMENTS: currentStep == 9 ? yup.bool().oneOf([true], 'Document is required') : "",
            // INCOME: currentStep == 9 ? yup.bool().oneOf([true], 'Image is required') : "",
        }),

        onSubmit: async values => {
            if (currentStep == 0) {
                const response = await postVerification(lender.lender_token, values)
                if (response.status == true) {
                    setCurrentStep(currentStep + 1)
                    toast.success(response.message)
                    setuser_loan_id(response.data.user_loan_id)
                    await GetEmployeeDetail(lender.lender_token, { user_loan_id: response.data.user_loan_id }).then((customerResponse) => {
                        let customerData = customerResponse.data;

                        if (customerResponse.status) {
                            // console.log("after ", customerResponse.data)
                            // AddForm.setFieldValue('employer_id', customerData.employer_id ? customerData.employer_id : '');
                            // AddForm.setFieldValue('employer_id_true', customerData.employer_id ? true : false);
                            AddForm.setFieldValue('require_loan_amount', customerData.require_loan_amount != 0 ? customerData.require_loan_amount : '');
                            AddForm.setFieldValue('require_loan_amount_true', customerData.require_loan_amount ? true : false);
                            AddForm.setFieldValue('loan_type', customerData.loan_type ? customerData.loan_type : '');
                            AddForm.setFieldValue('loan_type_true', customerData.loan_type ? true : false);
                            AddForm.setFieldValue('tin', customerData.tin ? customerData.tin : '');
                            AddForm.setFieldValue('tin_true', customerData.tin ? true : false);
                            AddForm.setFieldValue('fnpf', customerData.fnpf ? customerData.fnpf : '');
                            AddForm.setFieldValue('fnpf_true', customerData.fnpf ? true : false);
                            AddForm.setFieldValue('first_name', customerData.first_name ? customerData.first_name : '');
                            AddForm.setFieldValue('first_name_true', customerData.first_name ? true : false);
                            AddForm.setFieldValue('middle_name', customerData.middle_name ? customerData.middle_name : '');
                            AddForm.setFieldValue('middle_name_true', customerData.middle_name.length > 0 ? true : false);
                            AddForm.setFieldValue('last_name', customerData.last_name ? customerData.last_name : '');
                            AddForm.setFieldValue('last_name_true', customerData.last_name ? true : false);
                            AddForm.setFieldValue('residental_address', customerData.residental_address ? customerData.residental_address : '');
                            AddForm.setFieldValue('residental_address_true', customerData.residental_address ? true : false);
                            AddForm.setFieldValue('postal_address', customerData.postal_address ? customerData.postal_address : '');
                            AddForm.setFieldValue('postal_address_true', customerData.postal_address ? true : false);
                            AddForm.setFieldValue('previus_address', customerData.previus_address ? customerData.previus_address : '');
                            AddForm.setFieldValue('previus_address_true', customerData.previus_address ? true : false);
                            AddForm.setFieldValue('period', customerData.period ? customerData.period : '');
                            AddForm.setFieldValue('period_true', customerData.period ? true : false);
                            AddForm.setFieldValue('postal_period', customerData.postal_period ? customerData.postal_period : '');
                            AddForm.setFieldValue('postal_period_true', customerData.postal_period ? true : false);
                            AddForm.setFieldValue('previous_period', customerData.previous_period ? customerData.previous_period : '');
                            AddForm.setFieldValue('previous_period_true', customerData.previous_period ? true : false);
                            AddForm.setFieldValue('account_name', customerData.account_name ? customerData.account_name : '');
                            AddForm.setFieldValue('account_name_true', customerData.account_name ? true : false);
                            AddForm.setFieldValue('account_number', customerData.account_number ? customerData.account_number : '');
                            AddForm.setFieldValue('account_number_true', customerData.account_number ? true : false);
                            AddForm.setFieldValue('bank_name', customerData.bank_name ? customerData.bank_name : '');
                            AddForm.setFieldValue('bank_name_true', customerData.bank_name ? true : false);
                            AddForm.setFieldValue('branch', customerData.branch ? customerData.branch : '');
                            AddForm.setFieldValue('branch_true', customerData.branch ? true : false);
                            AddForm.setFieldValue('personal_email', customerData.personal_email ? customerData.personal_email : '');
                            AddForm.setFieldValue('personal_email_true', customerData.personal_email ? true : false);
                            AddForm.setFieldValue('work_email', customerData.work_email ? customerData.work_email : '');
                            AddForm.setFieldValue('work_email_true', customerData.work_email ? true : false);
                            AddForm.setFieldValue('telephone_home', customerData.telephone_home ? customerData.telephone_home : '');
                            AddForm.setFieldValue('telephone_home_true', customerData.telephone_home ? true : false);
                            AddForm.setFieldValue('telephone_work', customerData.telephone_work ? customerData.telephone_work : '');
                            AddForm.setFieldValue('telephone_work_true', customerData.telephone_work ? true : false);
                            AddForm.setFieldValue('martial_status', customerData.martial_status ? customerData.martial_status : '');
                            AddForm.setFieldValue('martial_status_true', customerData.martial_status ? true : false);
                            AddForm.setFieldValue('date_of_birth', customerData.date_of_birth ? customerData.date_of_birth : '');
                            AddForm.setFieldValue('date_of_birth_true', customerData.date_of_birth ? true : false);
                            AddForm.setFieldValue('occupation', customerData.occupation ? customerData.occupation : '');
                            AddForm.setFieldValue('occupation_true', customerData.occupation ? true : false);
                            AddForm.setFieldValue('current_employer', customerData.current_employer ? customerData.current_employer : '');
                            AddForm.setFieldValue('current_employer_true', customerData.current_employer ? true : false);
                            AddForm.setFieldValue('employed_since_true', customerData.employed_since ? true : false);
                            AddForm.setFieldValue('employed_since', customerData.employed_since ? customerData.employed_since : '');
                            AddForm.setFieldValue('instagram', customerData.instagram ? customerData.instagram : '');
                            AddForm.setFieldValue('instagram_true', customerData.instagram ? true : false);
                            AddForm.setFieldValue('linkedin', customerData.linkedin ? customerData.linkedin : '');
                            AddForm.setFieldValue('linkedin_true', customerData.linkedin ? true : false);
                            AddForm.setFieldValue('facebook', customerData.facebook ? customerData.facebook : '');
                            AddForm.setFieldValue('facebook_true', customerData.facebook ? true : false);
                            AddForm.setFieldValue('current_office_address', customerData.current_office_address ? customerData.current_office_address : '');
                            AddForm.setFieldValue('current_office_address_true', customerData.current_office_address ? true : false);
                            AddForm.setFieldValue('previus_employer', customerData.previus_employer ? customerData.previus_employer : '');
                            AddForm.setFieldValue('previus_employer_true', customerData.previus_employer ? true : false);
                            AddForm.setFieldValue('previus_office_address', customerData.previus_office_address ? customerData.previus_office_address : '');
                            AddForm.setFieldValue('previus_office_address_true', customerData.previus_office_address ? true : false);
                            AddForm.setFieldValue('previous_employer_period', customerData.previous_employer_period ? customerData.previous_employer_period : '');
                            AddForm.setFieldValue('previous_employer_period_true', customerData.previous_employer_period ? true : false);
                            AddForm.setFieldValue('house_est_value', customerData.house_est_value ? customerData.house_est_value : '');
                            AddForm.setFieldValue('house_est_value_true', customerData.house_est_value ? true : false);
                            AddForm.setFieldValue('mortgaged', customerData.mortgaged ? customerData.mortgaged : '');
                            AddForm.setFieldValue('mortgaged_true', customerData.mortgaged ? true : false);
                            AddForm.setFieldValue('monthly_payment', customerData.monthly_payment ? customerData.monthly_payment : '');
                            AddForm.setFieldValue('monthly_payment_true', customerData.monthly_payment ? true : false);
                            AddForm.setFieldValue('notliving_house', customerData.notliving_house ? customerData.notliving_house : '');
                            AddForm.setFieldValue('notliving_house_true', customerData.notliving_house ? true : false);
                            AddForm.setFieldValue('mortgage_balance', customerData.mortgage_balance ? customerData.mortgage_balance : '');
                            AddForm.setFieldValue('mortgage_balance_true', customerData.mortgage_balance ? true : false);
                            AddForm.setFieldValue('pay_cycle', customerData.pay_cycle ? customerData.pay_cycle : '');
                            AddForm.setFieldValue('pay_cycle_true', customerData.pay_cycle ? true : false);
                            AddForm.setFieldValue('annual_salary', customerData.annual_salary ? customerData.annual_salary : '');
                            AddForm.setFieldValue('annual_salary_true', customerData.annual_salary ? true : false);
                            AddForm.setFieldValue('other_income', customerData.other_income ? customerData.other_income : '');
                            AddForm.setFieldValue('other_income_true', customerData.other_income ? true : false);
                            AddForm.setFieldValue('monthly_income', customerData.monthly_income ? customerData.monthly_income : '');
                            AddForm.setFieldValue('monthly_income_true', customerData.monthly_income ? true : false);
                            AddForm.setFieldValue('house_value', customerData.house_value ? customerData.house_value : '');
                            AddForm.setFieldValue('house_value_true', customerData.house_value ? true : false);
                            AddForm.setFieldValue('car_value', customerData.car_value ? customerData.car_value : '');
                            AddForm.setFieldValue('car_value_true', customerData.car_value ? true : false);
                            AddForm.setFieldValue('land_value', customerData.land_value ? customerData.land_value : '');
                            AddForm.setFieldValue('land_value_true', customerData.land_value ? true : false);
                            AddForm.setFieldValue('life_policy', customerData.life_policy ? customerData.life_policy : '');
                            AddForm.setFieldValue('life_policy_true', customerData.life_policy ? true : false);
                            AddForm.setFieldValue('debtors', customerData.debtors ? customerData.debtors : '');
                            AddForm.setFieldValue('debtors_true', customerData.debtors ? true : false);
                            AddForm.setFieldValue('personal_effects', customerData.personal_effects ? customerData.personal_effects : '');
                            AddForm.setFieldValue('personal_effects_true', customerData.personal_effects ? true : false);
                            AddForm.setFieldValue('cash_on_hand', customerData.cash_on_hand ? customerData.cash_on_hand : '');
                            AddForm.setFieldValue('cash_on_hand_true', customerData.cash_on_hand ? true : false);
                            AddForm.setFieldValue('cash_at_bank', customerData.cash_at_bank ? customerData.cash_at_bank : '');
                            AddForm.setFieldValue('cash_at_bank_true', customerData.cash_at_bank ? true : false);
                            AddForm.setFieldValue('mortgage_loan_balnce', customerData.mortgage_loan_balnce ? customerData.mortgage_loan_balnce : '');
                            AddForm.setFieldValue('mortgage_loan_balnce_true', customerData.mortgage_loan_balnce ? true : false);
                            AddForm.setFieldValue('home_loan', customerData.home_loan ? customerData.home_loan : '');
                            AddForm.setFieldValue('home_loan_true', customerData.home_loan ? true : false);
                            AddForm.setFieldValue('otherloan_balance', customerData.otherloan_balance ? customerData.otherloan_balance : '');
                            AddForm.setFieldValue('otherloan_balance_true', customerData.otherloan_balance ? true : false);
                            // AddForm.setFieldValue('liabilites', customerData.liabilites ? customerData.liabilites : '');
                            // AddForm.setFieldValue('liabilites_true', customerData.liabilites ? true : false);
                            AddForm.setFieldValue('total_current_deduction', customerData.total_current_deduction ? customerData.total_current_deduction : '');
                            AddForm.setFieldValue('total_current_deduction_true', customerData.total_current_deduction ? true : false);
                            AddForm.setFieldValue('rent', customerData.rent ? customerData.rent : '');
                            AddForm.setFieldValue('rent_true', customerData.rent ? true : false);
                            AddForm.setFieldValue('electricity', customerData.electricity ? customerData.electricity : '');
                            AddForm.setFieldValue('electricity_true', customerData.electricity ? true : false);
                            AddForm.setFieldValue('bill_for_phone', customerData.bill_for_phone ? customerData.bill_for_phone : '');
                            AddForm.setFieldValue('bill_for_phone_true', customerData.bill_for_phone ? true : false);
                            AddForm.setFieldValue('bill_for_water', customerData.bill_for_water ? customerData.bill_for_water : '');
                            AddForm.setFieldValue('bill_for_water_true', customerData.bill_for_water ? true : false);
                            AddForm.setFieldValue('house_keeping', customerData.house_keeping ? customerData.house_keeping : '');
                            AddForm.setFieldValue('house_keeping_true', customerData.house_keeping ? true : false);
                            AddForm.setFieldValue('school_fees', customerData.school_fees ? customerData.school_fees : '');
                            AddForm.setFieldValue('school_fees_true', customerData.school_fees ? true : false);
                            AddForm.setFieldValue('travelling', customerData.travelling ? customerData.travelling : '');
                            AddForm.setFieldValue('travelling_true', customerData.travelling ? true : false);
                            AddForm.setFieldValue('entertainment', customerData.entertainment ? customerData.entertainment : '');
                            AddForm.setFieldValue('entertainment_true', customerData.entertainment ? true : false);
                            AddForm.setFieldValue('hire_payments', customerData.hire_payments ? customerData.hire_payments : '');
                            AddForm.setFieldValue('hire_payments_true', customerData.hire_payments ? true : false);
                            AddForm.setFieldValue('life_insurance', customerData.life_insurance ? customerData.life_insurance : '');
                            AddForm.setFieldValue('life_insurance_true', customerData.life_insurance ? true : false);


                            AddForm.setFieldValue('disclaimer_q1', customerData.disclaimer_q1 ? customerData.disclaimer_q1 : '');
                            AddForm.setFieldValue('disclaimer_q1_true', customerData.disclaimer_q1 > 0 ? true : false);
                            AddForm.setFieldValue('disclaimer_q2', customerData.disclaimer_q2 ? customerData.disclaimer_q2 : '');
                            AddForm.setFieldValue('purpose_of_ewa_advance', customerData.purpose_of_ewa_advance ? customerData.purpose_of_ewa_advance : '');
                            AddForm.setFieldValue('purpose_of_ewa_advance_true', customerData.purpose_of_ewa_advance ? true : false);
                            AddForm.setFieldValue('monthly_business_income', customerData.monthly_business_income ? customerData.monthly_business_income : '');
                            AddForm.setFieldValue('monthly_business_income_true', customerData.monthly_business_income > 0 ? true : false);
                            AddForm.setFieldValue('is_consent1', customerData.is_consent1 ? customerData.is_consent1 : '');
                            AddForm.setFieldValue('is_consent1_true', customerData.is_consent1 > 0 ? true : false);
                            AddForm.setFieldValue('disclaimer_q2_true', customerData.disclaimer_q2 > 0 ? true : false);
                            AddForm.setFieldValue('is_consent', customerData.is_consent ? customerData.is_consent : '');
                            AddForm.setFieldValue('is_consent_true', customerData.is_consent > 0 ? true : false);


                            getImage('SIGNATURE', response.data.user_loan_id)
                            getImage('PAYSLIPS', response.data.user_loan_id)
                            getImage('PHOTO_ID', response.data.user_loan_id)
                            getImage('EMPLOYMENT_LETTER', response.data.user_loan_id)
                            getImage('TIN_LETTER', response.data.user_loan_id)
                            getImage('LOAN_STATEMENTS', response.data.user_loan_id)
                            getImage('BANK_STATEMENTS', response.data.user_loan_id)
                            getImage('INCOME', response.data.user_loan_id)
                        }
                    })
                } else if (response.status == false) {
                    toast.error(response.message)
                }
            } else {
                if (currentStep == 9) {
                    await Addborrower(lender.lender_token, { finalSubmit: 1, user_loan_id: user_loan_id }).then((res) => {
                        if (res.status == true) {
                            toast.success(res.message)
                            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/manage_ewa_advance');
                        } else {

                        }
                    })

                } else {
                    setCurrentStep(currentStep + 1)
                }
            }

        }
    });

    const Total_Liabilities = parseFloat(AddForm.values.mortgage_loan_balnce ? AddForm.values.mortgage_loan_balnce : 0)
        + parseFloat(AddForm.values.home_loan ? AddForm.values.home_loan : 0)
        + parseFloat(AddForm.values.otherloan_balance ? AddForm.values.otherloan_balance : 0)
        + parseFloat(AddForm.values.total_current_deduction ? AddForm.values.total_current_deduction : 0)

    const inputFile = useRef(null);
    const inputFile1 = useRef(null);
    const inputFile2 = useRef(null);
    const inputFile3 = useRef(null);
    const inputFile4 = useRef(null);
    const inputFile5 = useRef(null);
    const inputFile6 = useRef(null);
    const inputFile7 = useRef(null);

    const handleReset = () => {
        if (inputFile.current) {
            inputFile.current.type = 'text';
            inputFile.current.type = 'file';
            inputFile.current.value = '';
        }
        if (inputFile1.current) {
            inputFile1.current.type = 'text';
            inputFile1.current.type = 'file';
            inputFile1.current.value = '';
        }
        if (inputFile2.current) {
            inputFile2.current.type = 'text';
            inputFile2.current.type = 'file';
            inputFile2.current.value = '';
        }

        if (inputFile3.current) {
            inputFile3.current.type = 'text';
            inputFile3.current.type = 'file';
            inputFile3.current.value = '';
        }
        if (inputFile4.current) {
            inputFile4.current.type = 'text';
            inputFile4.current.type = 'file';
            inputFile4.current.value = '';
        }

        if (inputFile5.current) {
            inputFile5.current.type = 'text';
            inputFile5.current.type = 'file';
            inputFile5.current.value = '';
        }
        if (inputFile6.current) {
            inputFile6.current.type = 'text';
            inputFile6.current.type = 'file';
            inputFile6.current.value = '';
        }

        if (inputFile7.current) {
            inputFile7.current.type = 'text';
            inputFile7.current.type = 'file';
            inputFile7.current.value = '';
        }

    };

    const deletedoc = async (id, type) => {
        const res = await Deletedocument(lender.lender_token, id)
        if (res.status == "success") {
            toast.success(res.message)
            // GetEmployeeDetail(admin.admin_token)
            getImage(type)
        } else {
            toast.error(res.message)
        }
    }

    const annualSalary = parseFloat(AddForm.values.annual_salary ? AddForm.values.annual_salary : 0);
    const otherIncome = parseFloat(AddForm.values.other_income ? AddForm.values.other_income : 0);

    const monthly_salary = ((annualSalary / 12) + otherIncome).toFixed(2);



    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">
                    {/* <h4 className="page-title mb-0 text-primary">Add Employee</h4> */}
                    <h4 className="page-title mb-0 text-primary">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/lender/manage_ewa_advance" >Manage EWA Advance</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Add EWA Advance</li>
                            </ol>
                        </nav>
                    </h4>
                </div>
                <div className="page-rightheader">
                    <div className="btn-list">
                        {/* <Link to={prefix + "/upload_employees"} className="btn btn-primary mt-2" ><i className="fa fa-plus me-2"></i> Upload Bulk Employee</Link> */}
                    </div>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={AddForm.handleSubmit} >

                {/*/////////// Mobile Number Verification Start  =========>>>>>>>>>>>*/}

                <fieldset className="mb-3" style={currentStep == 0 ? { display: "block" } : { display: "none" }}>
                    <h1 className="mb-0 mt-1">Verification</h1>
                    <div className="stepform-newdesign">
                        <div className="row">
                            <div className="col-4 col-md-5 mt-3">
                                <label>Mobile No.</label>
                                <input type="number" name="mobile_number" className="form-control"
                                    value={AddForm.values.mobile_number} placeholder="Enter Mobile No."
                                    {...AddForm.getFieldProps("mobile_number")}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                            e.preventDefault();
                                        }

                                    }}
                                />
                                {AddForm.touched.mobile_number && Object.keys(AddForm.errors)[0] == 'mobile_number' && AddForm.errors.mobile_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.mobile_number}</div> : ''}

                            </div>

                        </div>
                    </div>
                    <input type="submit" className="next action-button apply-now-btn ml-00 mt-2" value="Continue" />
                </fieldset>

                {/*/////////// Mobile Number Verification Start  =========>>>>>>>>>>>*/}

                {/*/////////// Personal Info Start(1)  =========>>>>>>>>>>>*/}

                <fieldset className="mb-3" style={currentStep == 1 ? { display: "" } : { display: "none" }}>

                    <h3 className="mb-2">Personal Info</h3>
                    <div className="stepform-newdesign">
                        <div className="row">
                            <div className="form-group col-md-3">
                                <label>Required EWA Advance Amount
                                    {AddForm.values.require_loan_amount ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" name="annual_salary" className="form-control" placeholder="Enter Required Amount" {...AddForm.getFieldProps("require_loan_amount")} onBlur={updateEmployeeData} />
                                {AddForm.touched.require_loan_amount && Object.keys(AddForm.errors)[0] == 'require_loan_amount' && AddForm.errors.require_loan_amount && !AddForm.values.require_loan_amount ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.require_loan_amount}</div> : ''}
                                {/* {AddForm.touched.annual_salary && AddForm.errors.annual_salary ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.annual_salary}</div> : ''} */}

                            </div>
                            <div className="form-group col-md-3">
                                <label>EWA Advance Type
                                    {/* {document.getElementsByName('employer_id')[0].value !== '' ? */}
                                    {AddForm.values.loan_type_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <select className="form-control" name="loan_type" /* value={AddForm.values.loan_type} */ {...AddForm.getFieldProps("loan_type")} onBlur={updateEmployeeData}>
                                    <option value="">Select EWA Advance Type</option>
                                    <option value="personal_loan">Personal </option>
                                    <option value="business_loan">Business </option>
                                    {/* {employer?.map((item, index) => {
                                        return (
                                            <option key={index} value={item.user_id}>{item.company_name}</option>
                                        )
                                    })} */}
                                </select>
                                {AddForm.touched.loan_type && Object.keys(AddForm.errors)[0] == 'loan_type' && AddForm.errors.loan_type ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.loan_type}</div> : ''}
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">TIN
                                    {AddForm.values.tin_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter TIN No." name="tin" {...AddForm.getFieldProps("tin")} onBlur={updateEmployeeData} />

                                    </div>
                                    {AddForm.touched.tin && Object.keys(AddForm.errors)[0] == 'tin' && AddForm.errors.tin ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.tin}</div> : ''}

                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">FNPF
                                    {AddForm.values.fnpf_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" name="fnpf" placeholder="Enter FNPF No." {...AddForm.getFieldProps("fnpf")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.fnpf && Object.keys(AddForm.errors)[0] == 'fnpf' && AddForm.errors.fnpf ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.fnpf}</div> : ''}

                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Select
                                    {AddForm.values.select_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="select"  {...AddForm.getFieldProps("select")} onBlur={updateEmployeeData} >
                                                <option value=''>--Select--</option>
                                                <option value='Mr'>Mr</option>
                                                <option value='Mrs'>Mrs</option>
                                                <option value='Mis'>Mis</option>
                                            </select>
                                        </div>
                                        {AddForm.touched.select && Object.keys(AddForm.errors)[0] == 'select' && AddForm.errors.select ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.select}</div> : ''}
                                    </div>
                                </div>
                            </div >
                            <div className="form-group col-md-3">
                                <label className="form-label">First Name
                                    {AddForm.values.first_name_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter First Name" {...AddForm.getFieldProps("first_name")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.first_name && Object.keys(AddForm.errors)[0] == 'first_name' && AddForm.errors.first_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.first_name}</div> : ''}
                                    {/* {AddForm.touched.first_name && AddForm.errors.first_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.first_name}</div> : ''} */}
                                </div>
                            </div>
                            {/* </div>       
                    
                        <div className="col-xl-8 col-lg-8 col-md-12 col-xm-12"> */}

                            <div className="form-group col-md-3">
                                <label className="form-label">Middle Name
                                    {AddForm.values.middle_name_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Middle Name" {...AddForm.getFieldProps("middle_name")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.middle_name && Object.keys(AddForm.errors)[0] == 'middle_name' && AddForm.errors.middle_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.middle_name}</div> : ''}
                                    {/* {AddForm.touched.middle_name && AddForm.errors.middle_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.middle_name}</div> : ''} */}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label"> Last Name
                                    {AddForm.values.last_name_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Last Name" {...AddForm.getFieldProps("last_name")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.last_name && Object.keys(AddForm.errors)[0] == 'last_name' && AddForm.errors.last_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.last_name}</div> : ''}
                                    {/* {AddForm.touched.last_name && AddForm.errors.last_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.last_name}</div> : ''} */}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Residential Address
                                    {AddForm.values.residental_address_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Residential Address"  {...AddForm.getFieldProps("residental_address")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.residental_address && Object.keys(AddForm.errors)[0] == 'residental_address' && AddForm.errors.residental_address ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.residental_address}</div> : ''}
                                    {/* {AddForm.touched.residental_address && AddForm.errors.residental_address ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.residental_address}</div> : ''} */}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Postal Address
                                    {AddForm.values.postal_address_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Postal Address" {...AddForm.getFieldProps("postal_address")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.postal_address && Object.keys(AddForm.errors)[0] == 'postal_address' && AddForm.errors.postal_address ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.postal_address}</div> : ''}
                                    {/* {AddForm.touched.postal_address && AddForm.errors.postal_address ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.postal_address}</div> : ''} */}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Previous Address
                                    {AddForm.values.previus_address_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Previous Address"  {...AddForm.getFieldProps("previus_address")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.previus_address && Object.keys(AddForm.errors)[0] == 'previus_address' && AddForm.errors.previus_address ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.previus_address}</div> : ''}
                                    {/* {AddForm.touched.previus_address && AddForm.errors.previus_address ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.previus_address}</div> : ''} */}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Previous Period
                                    {AddForm.values.previous_period_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="previous_period" {...AddForm.getFieldProps("previous_period")} onBlur={updateEmployeeData}>
                                                <option value='' >--Period--</option>
                                                <option value='0 to 1 year'>0 to 1 year</option>
                                                <option value='1 to 2 year'>1 to 2 year</option>
                                                <option value='2 to 3 year'>2 to 3 year</option>
                                                <option value='3 to 4 year'>3 to 4 year</option>
                                                <option value='4 to 5 year'>4 to 5 year</option>
                                                <option value='Above 5 year'>Above 5 year</option>
                                            </select>
                                        </div>
                                        {AddForm.touched.previous_period && Object.keys(AddForm.errors)[0] == 'previous_period' && AddForm.errors.previous_period ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.previous_period}</div> : ''}
                                        {/* {AddForm.touched.previous_period && AddForm.errors.previous_period ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.previous_period}</div> : ''} */}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Postal Period
                                    {AddForm.values.postal_period_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="postal_period" {...AddForm.getFieldProps("postal_period")} onBlur={updateEmployeeData} >
                                                <option value='' >--Period--</option>
                                                <option value='0 to 1 year'>0 to 1 year</option>
                                                <option value='1 to 2 year'>1 to 2 year</option>
                                                <option value='2 to 3 year'>2 to 3 year</option>
                                                <option value='3 to 4 year'>3 to 4 year</option>
                                                <option value='4 to 5 year'>4 to 5 year</option>
                                                <option value='Above 5 year'>Above 5 year</option>
                                            </select>
                                        </div>
                                        {AddForm.touched.postal_period && Object.keys(AddForm.errors)[0] == 'postal_period' && AddForm.errors.postal_period ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.postal_period}</div> : ''}
                                        {/* {AddForm.touched.postal_period && AddForm.errors.postal_period ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.postal_period}</div> : ''} */}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Residential Period
                                    {AddForm.values.period_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="period" {...AddForm.getFieldProps("period")} onBlur={updateEmployeeData} >
                                                <option value='' >--Period--</option>
                                                <option value='0 to 1 year'>0 to 1 year</option>
                                                <option value='1 to 2 year'>1 to 2 year</option>
                                                <option value='2 to 3 year'>2 to 3 year</option>
                                                <option value='3 to 4 year'>3 to 4 year</option>
                                                <option value='4 to 5 year'>4 to 5 year</option>
                                                <option value='Above 5 year'>Above 5 year</option>
                                            </select>
                                        </div>
                                        {AddForm.touched.period && Object.keys(AddForm.errors)[0] == 'period' && AddForm.errors.period ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.period}</div> : ''}
                                        {/* {AddForm.touched.period && AddForm.errors.period ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.period}</div> : ''} */}
                                    </div>
                                </div> </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Bank Account Holder Name
                                    {AddForm.values.account_name_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Account Name"  {...AddForm.getFieldProps("account_name")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.account_name && Object.keys(AddForm.errors)[0] == 'account_name' && AddForm.errors.account_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.account_name}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Bank Account Number
                                    {AddForm.values.account_number_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter Account Number"  {...AddForm.getFieldProps("account_number")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.account_number && Object.keys(AddForm.errors)[0] == 'account_number' && AddForm.errors.account_number ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.account_number}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Bank Name
                                    {AddForm.values.bank_name_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Bank Name"  {...AddForm.getFieldProps("bank_name")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.bank_name && Object.keys(AddForm.errors)[0] == 'bank_name' && AddForm.errors.bank_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.bank_name}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Branch Name
                                    {AddForm.values.branch_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Branch Name"  {...AddForm.getFieldProps("branch")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.branch && Object.keys(AddForm.errors)[0] == 'branch' && AddForm.errors.branch ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.branch}</div> : ''}
                                </div>
                            </div>

                        </div>
                    </div>
                    <input type="submit" name="next" className="btn btn-primary " value="Continue" />
                </fieldset>


                {/*/////////// Personal Info End(1)  =========>>>>>>>>>>>*/}


                {/*/////////// Personal Info Start(2)  =========>>>>>>>>>>>*/}

                <fieldset className="mb-3" style={currentStep == 2 ? { display: "" } : { display: "none" }}>

                    <h3 className="mb-2">Personal Info(2)</h3>
                    {/*////////////////// First Col Start =================>>>>>>>>>>>>>>*/}
                    <button type="button" name="previous" className="previous action-button-previous" onClick={() => setCurrentStep(currentStep - 1)} ><i className="fa-solid fa-arrow-left-long fa-fw"></i> Back</button>
                    <div className="stepform-newdesign">
                        <div className="row">

                            <div className="form-group col-md-3">
                                <label className="form-label">Personal Email
                                    {AddForm.values.personal_email_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="email" className="form-control" placeholder="Enter Personal Email" {...AddForm.getFieldProps("personal_email")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.personal_email && Object.keys(AddForm.errors)[0] == 'personal_email' && AddForm.errors.personal_email ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.personal_email}</div> : ''}
                                    {/* {AddForm.touched.personal_email && AddForm.errors.personal_email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.personal_email}</div> : ''} */}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Work Email
                                    {AddForm.values.work_email_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="email" className="form-control" placeholder="Enter Work Email" {...AddForm.getFieldProps("work_email")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.work_email && Object.keys(AddForm.errors)[0] == 'work_email' && AddForm.errors.work_email ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.work_email}</div> : ''}
                                    {/* {AddForm.touched.work_email && AddForm.errors.work_email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.work_email}</div> : ''} */}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Telephone(Home)
                                    {AddForm.values.telephone_home_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter Telephone No." {...AddForm.getFieldProps("telephone_home")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.telephone_home && Object.keys(AddForm.errors)[0] == 'telephone_home' && AddForm.errors.telephone_home ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.telephone_home}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Telephone(Work)
                                    {AddForm.values.telephone_work_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter Telephone No." {...AddForm.getFieldProps("telephone_work")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.telephone_work && Object.keys(AddForm.errors)[0] == 'telephone_work' && AddForm.errors.telephone_work ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.telephone_work}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Date of Birth
                                    {AddForm.values.date_of_birth_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="date" className="form-control" placeholder="Enter Your DOB" /* max="2002-01-01" */
                                            max={new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().split('T')[0]}
                                            {...AddForm.getFieldProps("date_of_birth")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.date_of_birth && Object.keys(AddForm.errors)[0] == 'date_of_birth' && AddForm.errors.date_of_birth ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.date_of_birth}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Marital Status
                                    {AddForm.values.martial_status_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="martial_status" {...AddForm.getFieldProps("martial_status")} onBlur={updateEmployeeData} >
                                                <option value=''>--Select--</option>
                                                <option value='married'>Married</option>
                                                <option value='unmarried'>Unmarried</option>
                                            </select>
                                        </div>
                                        {AddForm.touched.martial_status && Object.keys(AddForm.errors)[0] == 'martial_status' && AddForm.errors.martial_status ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.martial_status}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">No. of Dependents
                                    {AddForm.values.dependent_no_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter No. of Dependents"  {...AddForm.getFieldProps("dependent_no")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.dependent_no && Object.keys(AddForm.errors)[0] == 'dependent_no' && AddForm.errors.dependent_no ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.dependent_no}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Occupation
                                    {AddForm.values.occupation_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Occupation" {...AddForm.getFieldProps("occupation")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.occupation && Object.keys(AddForm.errors)[0] == 'occupation' && AddForm.errors.occupation ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.occupation}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Current Employer
                                    {AddForm.values.current_employer_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Current Employer" {...AddForm.getFieldProps("current_employer")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.current_employer && Object.keys(AddForm.errors)[0] == 'current_employer' && AddForm.errors.current_employer ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.current_employer}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Current Employer Address
                                    {AddForm.values.current_office_address_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Current Employer Address" {...AddForm.getFieldProps("current_office_address")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.current_office_address && Object.keys(AddForm.errors)[0] == 'current_office_address' && AddForm.errors.current_office_address ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.current_office_address}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Employed Period
                                    {AddForm.values.employed_since_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="employed_since" {...AddForm.getFieldProps("employed_since")} onBlur={updateEmployeeData}>
                                                <option value='' >--Period--</option>
                                                <option value='0 to 1 year'>0 to 1 year</option>
                                                <option value='1 to 2 year'>1 to 2 year</option>
                                                <option value='2 to 3 year'>2 to 3 year</option>
                                                <option value='3 to 4 year'>3 to 4 year</option>
                                                <option value='4 to 5 year'>4 to 5 year</option>
                                                <option value='Above 5 year'>Above 5 year</option>
                                            </select>
                                        </div>
                                        {AddForm.touched.employed_since && Object.keys(AddForm.errors)[0] == 'employed_since' && AddForm.errors.employed_since ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.employed_since}</div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <input type="submit" name="next" className="btn btn-primary " value="Continue" />
                </fieldset>
                {/*/////////// Personal Info End(2)  =========>>>>>>>>>>>*/}


                {/*/////////// Personal Info Start(3)  =========>>>>>>>>>>>*/}

                <fieldset className="mb-3" style={currentStep == 3 ? { display: "" } : { display: "none" }}>

                    <h3 className="mb-2">Personal Info(3)</h3>
                    {/*////////////////// First Col Start =================>>>>>>>>>>>>>>*/}
                    <button type="button" name="previous" className="previous action-button-previous" onClick={() => setCurrentStep(currentStep - 1)} ><i className="fa-solid fa-arrow-left-long fa-fw"></i> Back</button>
                    <div className="stepform-newdesign">
                        <div className="row">
                            <div className="form-group col-md-3">
                                <label className="form-label">Previous Employer
                                    {AddForm.values.previus_employer_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" name="previus_employer" placeholder="Enter Previous Employer" {...AddForm.getFieldProps("previus_employer")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.previus_employer && Object.keys(AddForm.errors)[0] == 'previus_employer' && AddForm.errors.previus_employer ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.previus_employer}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Previous Employer Address
                                    {AddForm.values.previus_office_address_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Previous Employer Address" {...AddForm.getFieldProps("previus_office_address")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.previus_office_address && Object.keys(AddForm.errors)[0] == 'previus_office_address' && AddForm.errors.previus_office_address ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.previus_office_address}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Previous Employer Period
                                    {AddForm.values.previous_employer_period_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="previous_employer_period" {...AddForm.getFieldProps("previous_employer_period")} onBlur={updateEmployeeData}>
                                                <option value=''>Period</option>
                                                <option value='0 to 1 year'>0 to 1 year</option>
                                                <option value='1 to 2 year'>1 to 2 year</option>
                                                <option value='2 to 3 year'>2 to 3 year</option>
                                                <option value='3 to 4 year'>3 to 4 year</option>
                                                <option value='4 to 5 year'>4 to 5 year</option>
                                                <option value='Above 5 year'>Above 5 year</option>
                                            </select>
                                        </div>
                                        {AddForm.touched.previous_employer_period && Object.keys(AddForm.errors)[0] == 'previous_employer_period' && AddForm.errors.previous_employer_period ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.previous_employer_period}</div> : ''}

                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Own House Estimated Value
                                    {AddForm.values.house_est_value_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter Estimated Value" {...AddForm.getFieldProps("house_est_value")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.house_est_value && Object.keys(AddForm.errors)[0] == 'house_est_value' && AddForm.errors.house_est_value ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.house_est_value}</div> : ''}

                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Mortgaged Bank Name
                                    {AddForm.values.mortgaged_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Mortgage Bank Name" {...AddForm.getFieldProps("mortgaged")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.mortgaged && Object.keys(AddForm.errors)[0] == 'mortgaged' && AddForm.errors.mortgaged ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.mortgaged}</div> : ''}
                                </div>
                            </div>

                            <div className="form-group col-md-3">
                                <label className="form-label">Monthly Repayment
                                    {AddForm.values.monthly_payment_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter Monthly Repayment" {...AddForm.getFieldProps("monthly_payment")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.monthly_payment && Object.keys(AddForm.errors)[0] == 'monthly_payment' && AddForm.errors.monthly_payment ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.monthly_payment}</div> : ''}
                                </div>
                            </div>

                            <div className="form-group col-md-3">
                                <label className="form-label">Living House Status
                                    {AddForm.values.notliving_house_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="notliving_house" {...AddForm.getFieldProps("notliving_house")} onBlur={updateEmployeeData}>
                                                <option value="">Living House Status</option>
                                                <option value='Yes'>Yes</option>
                                                <option value='No'>No</option>

                                            </select>
                                        </div>
                                        {AddForm.touched.notliving_house && Object.keys(AddForm.errors)[0] == 'notliving_house' && AddForm.errors.notliving_house ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.notliving_house}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Remaining Mortgage Balance
                                    {AddForm.values.mortgage_balance_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter Mortgage Balance" {...AddForm.getFieldProps("mortgage_balance")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.mortgage_balance && Object.keys(AddForm.errors)[0] == 'mortgage_balance' && AddForm.errors.mortgage_balance ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.mortgage_balance}</div> : ''}
                                </div>
                            </div>

                            <div className="form-group col-md-3">
                                <label className="form-label">LinkedIn ID
                                    {AddForm.values.linkedin_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter LinkedIn ID" {...AddForm.getFieldProps("linkedin")} onBlur={updateEmployeeData} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Facebook ID
                                    {AddForm.values.facebook_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Facebook ID" {...AddForm.getFieldProps("facebook")} onBlur={updateEmployeeData} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Instagram ID
                                    {AddForm.values.instagram_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Instagram ID" {...AddForm.getFieldProps("instagram")} onBlur={updateEmployeeData} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    {/* <div className="mt-2 mb-5">
                            <div className="col-md-12">
                                <input type="submit" name="next" className="next action-button apply-now-btn ml-00 mt-1" value="Submit" />
                            </div>
                        </div> */}
                    <input type="submit" className="btn btn-primary " value="Continue" />
                </fieldset>

                {/*/////////// Personal Info End(3)  =========>>>>>>>>>>>*/}

                {/*/////////// Income Info Start  =========>>>>>>>>>>>*/}

                <fieldset className="mb-3" style={currentStep == 4 ? { display: "block" } : { display: "none" }}>
                    <button type="button" name="previous" className="previous action-button-previous" onClick={() => setCurrentStep(currentStep - 1)} ><i className="fa-solid fa-arrow-left-long fa-fw"></i> Back</button>
                    <h1 className="mb-0 mt-1">Income Info</h1>
                    <div className="stepform-newdesign">
                        <div className="row">

                            <div className="col-4 col-md-5 mt-3">
                                <label>Pay Cycle
                                    {/* {document.getElementsByName('employer_id')[0].value !== '' ? */}
                                    {AddForm.values.pay_cycle_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <select className="form-control" name="pay_cycle" /* value={AddForm.values.pay_cycle} */ {...AddForm.getFieldProps("pay_cycle")} onBlur={updateEmployeeData}>
                                    <option value="">Select Pay Cycle</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Fortnightly">Fortnightly</option>
                                    <option value="Monthly">Monthly</option>
                                </select>
                                {AddForm.touched.pay_cycle && Object.keys(AddForm.errors)[0] == 'pay_cycle' && AddForm.errors.pay_cycle ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.pay_cycle}</div> : ''}
                            </div>
                            <div className="col-4 col-md-5 mt-3">
                                <label>Annual Salary
                                    {AddForm.values.annual_salary_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" name="annual_salary" className="form-control" placeholder="Enter Annual Salary" {...AddForm.getFieldProps("annual_salary")} onBlur={updateEmployeeData} />
                                {AddForm.touched.annual_salary && Object.keys(AddForm.errors)[0] == 'annual_salary' && AddForm.errors.annual_salary ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.annual_salary}</div> : ''}

                            </div>
                            <div className="col-4 col-md-5 mt-3">
                                <label>Other Monthly Income
                                    {AddForm.values.other_income ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" name="other_income" className="form-control" placeholder="Enter Other Income" {...AddForm.getFieldProps("other_income")} onBlur={updateEmployeeData} />
                                {AddForm.touched.other_income && Object.keys(AddForm.errors)[0] == 'other_income' && AddForm.errors.other_income ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.other_income}</div> : ''}

                            </div>
                            <div className="col-4 col-md-5 mt-3">
                                <label> Total Monthly Income  {monthly_salary > 0 ? <i className="fa-solid fa-check fa-fw checkgreen"></i> : ''} </label>
                                <input type="number" name="monthly_income" className="form-control" placeholder="Enter Total Monthly Income"
                                    value={monthly_salary ? monthly_salary : 0}
                                /* {...AddForm.getFieldProps("monthly_income")} */ onBlur={updateEmployeeData} disabled />
                                {AddForm.touched.monthly_income && Object.keys(AddForm.errors)[0] == 'monthly_income' && AddForm.errors.monthly_income ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.monthly_income}</div> : ''}

                            </div>

                        </div>
                    </div>
                    <input type="submit" name="next" className="next action-button apply-now-btn ml-00" value="Continue" />
                </fieldset>


                {/*/////////// Income Info End  =========>>>>>>>>>>>*/}

                {/*/////////// Asset Position Start  =========>>>>>>>>>>>*/}


                <fieldset className="mb-3" style={currentStep == 5 ? { display: "block" } : { display: "none" }}>
                    <button type="button" name="previous" className="previous action-button-previous" onClick={() => setCurrentStep(currentStep - 1)} ><i className="fa-solid fa-arrow-left-long fa-fw"></i> Back</button>
                    <h1 className="mb-0 mt-1">Asset Position</h1>
                    <div className="stepform-newdesign">
                        <div className="row">
                            <div className="col-4 col-md-4 mt-3">
                                <label>House Value
                                    {AddForm.values.house_value_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter House Value" {...AddForm.getFieldProps("house_value")} onBlur={updateEmployeeData} />
                                {AddForm.touched.house_value && Object.keys(AddForm.errors)[0] == 'house_value' && AddForm.errors.house_value ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.house_value}</div> : ''}

                                {/* {AddForm.touched.house_value && AddForm.errors.house_value ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.house_value}</div> : ''} */}

                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Car Value
                                    {AddForm.values.car_value_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Car Value" {...AddForm.getFieldProps("car_value")} onBlur={updateEmployeeData} />
                                {AddForm.touched.car_value && Object.keys(AddForm.errors)[0] == 'car_value' && AddForm.errors.car_value ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.car_value}</div> : ''}

                                {/* {AddForm.touched.car_value && AddForm.errors.car_value ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.car_value}</div> : ''} */}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Land Value
                                    {AddForm.values.land_value_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Total Land Value" {...AddForm.getFieldProps("land_value")} onBlur={updateEmployeeData} />
                                {AddForm.touched.land_value && Object.keys(AddForm.errors)[0] == 'land_value' && AddForm.errors.land_value ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.land_value}</div> : ''}

                                {/* {AddForm.touched.land_value && AddForm.errors.land_value ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.land_value}</div> : ''} */}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Life Policies
                                    {AddForm.values.life_policy_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Life Policies" {...AddForm.getFieldProps("life_policy")} onBlur={updateEmployeeData} />
                                {AddForm.touched.life_policy && Object.keys(AddForm.errors)[0] == 'life_policy' && AddForm.errors.life_policy ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.life_policy}</div> : ''}

                                {/* {AddForm.touched.life_policy && AddForm.errors.life_policy ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.life_policy}</div> : ''} */}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Debtors
                                    {AddForm.values.debtors_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Debtors" {...AddForm.getFieldProps("debtors")} onBlur={updateEmployeeData} />
                                {AddForm.touched.debtors && Object.keys(AddForm.errors)[0] == 'debtors' && AddForm.errors.debtors ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.debtors}</div> : ''}

                                {/* {AddForm.touched.debtors && AddForm.errors.debtors ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.debtors}</div> : ''} */}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Personal Effects
                                    {AddForm.values.personal_effects_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Personal Effects" {...AddForm.getFieldProps("personal_effects")} onBlur={updateEmployeeData} />
                                {AddForm.touched.personal_effects && Object.keys(AddForm.errors)[0] == 'personal_effects' && AddForm.errors.personal_effects ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.personal_effects}</div> : ''}

                                {/* {AddForm.touched.personal_effects && AddForm.errors.personal_effects ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.personal_effects}</div> : ''} */}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Cash On Hand
                                    {AddForm.values.cash_on_hand_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Cash On Hand" {...AddForm.getFieldProps("cash_on_hand")} onBlur={updateEmployeeData} />
                                {AddForm.touched.cash_on_hand && Object.keys(AddForm.errors)[0] == 'cash_on_hand' && AddForm.errors.cash_on_hand ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.cash_on_hand}</div> : ''}

                                {/* {AddForm.touched.cash_on_hand && AddForm.errors.cash_on_hand ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.cash_on_hand}</div> : ''} */}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Cash At Bank
                                    {AddForm.values.cash_at_bank_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Cash At Bank" {...AddForm.getFieldProps("cash_at_bank")} onBlur={updateEmployeeData} />
                                {AddForm.touched.cash_at_bank && Object.keys(AddForm.errors)[0] == 'cash_at_bank' && AddForm.errors.cash_at_bank ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.cash_at_bank}</div> : ''}

                                {/* {AddForm.touched.cash_at_bank && AddForm.errors.cash_at_bank ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.cash_at_bank}</div> : ''} */}
                            </div>
                        </div>
                    </div>
                    <input type="submit" name="next" className="next action-button apply-now-btn ml-00" value="Continue" />
                </fieldset>


                {/*/////////// Asset Position End  =========>>>>>>>>>>>*/}


                {/*/////////// Liabilities Start  =========>>>>>>>>>>>*/}

                <fieldset className="mb-3" style={currentStep == 6 ? { display: "block" } : { display: "none" }}>
                    <button type="button" name="previous" className="previous action-button-previous" onClick={() => setCurrentStep(currentStep - 1)} ><i className="fa-solid fa-arrow-left-long fa-fw"></i> Back</button>
                    <h1 className="mb-0 mt-1">Liabilities</h1>
                    <div className="stepform-newdesign">
                        <div className="row">
                            <div className="col-4 col-md-4 mt-3">
                                <label>Mortgage Loan Balance
                                    {AddForm.values.mortgage_loan_balnce_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Mortgage Loan Balance" {...AddForm.getFieldProps("mortgage_loan_balnce")} onBlur={updateEmployeeData} />
                                {AddForm.touched.mortgage_loan_balnce && Object.keys(AddForm.errors)[0] == 'mortgage_loan_balnce' && AddForm.errors.mortgage_loan_balnce ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.mortgage_loan_balnce}</div> : ''}

                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Home Loan
                                    {AddForm.values.home_loan_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Home Loan" {...AddForm.getFieldProps("home_loan")} onBlur={updateEmployeeData} />
                                {AddForm.touched.home_loan && Object.keys(AddForm.errors)[0] == 'home_loan' && AddForm.errors.home_loan ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.home_loan}</div> : ''}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Other Loan Payment
                                    {AddForm.values.otherloan_balance_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Other Loan Payment" {...AddForm.getFieldProps("otherloan_balance")} onBlur={updateEmployeeData} />
                                {AddForm.touched.otherloan_balance && Object.keys(AddForm.errors)[0] == 'otherloan_balance' && AddForm.errors.otherloan_balance ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.otherloan_balance}</div> : ''}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Total Current Deduction
                                    {AddForm.values.total_current_deduction_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Total Current Deduction" {...AddForm.getFieldProps("total_current_deduction")} onBlur={updateEmployeeData} />
                                {AddForm.touched.total_current_deduction && Object.keys(AddForm.errors)[0] == 'total_current_deduction' && AddForm.errors.total_current_deduction ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.total_current_deduction}</div> : ''}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Total Liabilities
                                    {Total_Liabilities > 0 ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Total Liabilities"
                                    value={Total_Liabilities ? Total_Liabilities.toFixed(2) : 0} disabled
                                // {...AddForm.getFieldProps("liabilites")} onBlur={updateEmployeeData}
                                />
                                {AddForm.touched.liabilites && Object.keys(AddForm.errors)[0] == 'liabilites' && AddForm.errors.liabilites ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.liabilites}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <input type="submit" name="next" className="next action-button apply-now-btn ml-00" value="Continue" />
                </fieldset>


                {/*/////////// Liabilities End  =========>>>>>>>>>>>*/}

                {/*/////////// Monthly Living Cost Start  =========>>>>>>>>>>>*/}

                <fieldset className="mb-3" style={currentStep == 7 ? { display: "block" } : { display: "none" }}>
                    <button type="button" name="previous" className="previous action-button-previous" onClick={() => setCurrentStep(currentStep - 1)} ><i className="fa-solid fa-arrow-left-long fa-fw"></i> Back</button>
                    <h1 className="mb-0 mt-1">Monthly Living Cost</h1>
                    <div className="stepform-newdesign">
                        <div className="row">
                            <div className="col-4 col-md-4 mt-3">
                                <label>Rent
                                    {AddForm.values.rent_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Rent" {...AddForm.getFieldProps("rent")} onBlur={updateEmployeeData} />
                                {AddForm.touched.rent && Object.keys(AddForm.errors)[0] == 'rent' && AddForm.errors.rent ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.rent}</div> : ''}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Electricity
                                    {AddForm.values.electricity_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Electricity" {...AddForm.getFieldProps("electricity")} onBlur={updateEmployeeData} />
                                {AddForm.touched.electricity && Object.keys(AddForm.errors)[0] == 'electricity' && AddForm.errors.electricity ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.electricity}</div> : ''}

                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Telephone Cost
                                    {AddForm.values.bill_for_phone_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Telephone Cost" {...AddForm.getFieldProps("bill_for_phone")} onBlur={updateEmployeeData} />
                                {AddForm.touched.bill_for_phone && Object.keys(AddForm.errors)[0] == 'bill_for_phone' && AddForm.errors.bill_for_phone ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.bill_for_phone}</div> : ''}

                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Water
                                    {AddForm.values.bill_for_water_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Water" {...AddForm.getFieldProps("bill_for_water")} onBlur={updateEmployeeData} />
                                {AddForm.touched.bill_for_water && Object.keys(AddForm.errors)[0] == 'bill_for_water' && AddForm.errors.bill_for_water ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.bill_for_water}</div> : ''}

                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>House Keeping
                                    {AddForm.values.house_keeping_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter House Keeping" {...AddForm.getFieldProps("house_keeping")} onBlur={updateEmployeeData} />
                                {AddForm.touched.house_keeping && Object.keys(AddForm.errors)[0] == 'house_keeping' && AddForm.errors.house_keeping ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.house_keeping}</div> : ''}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>School Fees
                                    {AddForm.values.school_fees_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter School Fees" {...AddForm.getFieldProps("school_fees")} onBlur={updateEmployeeData} />
                                {AddForm.touched.school_fees && Object.keys(AddForm.errors)[0] == 'school_fees' && AddForm.errors.school_fees ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.school_fees}</div> : ''}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Travelling/Petrol/Bus
                                    {AddForm.values.travelling_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Travelling/Petrol/Bus" {...AddForm.getFieldProps("travelling")} onBlur={updateEmployeeData} />
                                {AddForm.touched.travelling && Object.keys(AddForm.errors)[0] == 'travelling' && AddForm.errors.travelling ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.travelling}</div> : ''}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Entertainment/Video
                                    {AddForm.values.entertainment_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Entertainment/Video" {...AddForm.getFieldProps("entertainment")} onBlur={updateEmployeeData} />
                                {AddForm.touched.entertainment && Object.keys(AddForm.errors)[0] == 'entertainment' && AddForm.errors.entertainment ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.entertainment}</div> : ''}
                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Total Hire Purchase Payments
                                    {AddForm.values.hire_payments_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Total Hire Purchase Payments" {...AddForm.getFieldProps("hire_payments")} onBlur={updateEmployeeData} />
                                {AddForm.touched.hire_payments && Object.keys(AddForm.errors)[0] == 'hire_payments' && AddForm.errors.hire_payments ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.hire_payments}</div> : ''}

                            </div>
                            <div className="col-4 col-md-4 mt-3">
                                <label>Life Insurance
                                    {AddForm.values.life_insurance_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <input type="number" className="form-control" placeholder="Enter Life Insurance" {...AddForm.getFieldProps("life_insurance")} onBlur={updateEmployeeData} />
                                {AddForm.touched.life_insurance && Object.keys(AddForm.errors)[0] == 'life_insurance' && AddForm.errors.life_insurance ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.life_insurance}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <input type="submit" name="next" className="next action-button apply-now-btn ml-00" value="Continue" />
                </fieldset>


                {/*/////////// Monthly Living Cost End  =========>>>>>>>>>>>*/}

                {/*/////////// Disclaimer Start  =========>>>>>>>>>>>*/}

                <fieldset className="mb-3" style={currentStep == 8 ? { display: "block" } : { display: "none" }}>
                    {/* <form onSubmit={addSettlementForm.submitForm} > */}
                    <button type="button" name="previous" className="previous action-button-previous" onClick={() => setCurrentStep(currentStep - 1)} ><i className="fa-solid fa-arrow-left-long fa-fw"></i> Back</button>
                    {/* <form  onSubmit={addSettlementForm.handleSubmit}> */}
                    <h1 className="mb-0 mt-1">Disclaimer</h1>

                    <div className="stepform-newdesign">

                        <div className="row mb-3">
                            <label>Have you ever had legal proceedings against you for the recovery of a debt?
                                {AddForm.values.disclaimer_q1_true ?
                                    <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                    : ''}
                            </label>
                            <div className="custom-controls-stacked d-flex mb-3">
                                <label className="custom-control custom-radio diclamerpage">
                                    <input type="radio" className="custom-control-input NewCHeckBx" name="disclaimer_q1" value="1" checked={AddForm.values.disclaimer_q1 == 1} onChange={(e) => updateEmployeeData(e)} />
                                    <span className="custom-control-label">Yes</span>
                                </label>

                                <label className="custom-control custom-radio diclamerpage">
                                    <input type="radio" className="custom-control-input NewCHeckBx" name="disclaimer_q1" value="2" checked={AddForm.values.disclaimer_q1 == 2} onChange={(e) => updateEmployeeData(e)} />
                                    <span className="custom-control-label">No</span>
                                </label>
                            </div>
                            {AddForm.touched.disclaimer_q1 && Object.keys(AddForm.errors)[0] == 'disclaimer_q1' && AddForm.errors.disclaimer_q1 ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.disclaimer_q1}</div> : ''}

                        </div>

                        <div className="row mb-3">
                            <label>Have been ever declared a bankrupt or hand bankrupt proceedings against you?
                                {AddForm.values.disclaimer_q2_true ?
                                    <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                    : ''}
                            </label>
                            <div className="custom-controls-stacked d-flex mb-3">
                                <label className="custom-control custom-radio diclamerpage">
                                    <input type="radio" className="custom-control-input NewCHeckBx" name="disclaimer_q2" value="1" checked={AddForm.values.disclaimer_q2 == 1} onChange={(e) => updateEmployeeData(e)} />
                                    <span className="custom-control-label">Yes</span>
                                </label>

                                <label className="custom-control custom-radio diclamerpage">
                                    <input type="radio" className="custom-control-input NewCHeckBx" name="disclaimer_q2" value="2" checked={AddForm.values.disclaimer_q2 == 2} onChange={(e) => updateEmployeeData(e)} />
                                    <span className="custom-control-label">No</span>
                                </label>
                            </div>
                            {AddForm.touched.disclaimer_q2 && Object.keys(AddForm.errors)[0] == 'disclaimer_q2' && AddForm.errors.disclaimer_q2 ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.disclaimer_q2}</div> : ''}

                        </div>
                        <div className="row mb-3">
                            <h4>Consent 1
                                {/* {AddForm.values.is_consent_true ?
                                    <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                    : ''} */}
                            </h4>
                            <div className="form-group col-md-4">
                                <label className="form-label">Other Income Source
                                    {AddForm.values.purpose_of_ewa_advance_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="input-group">
                                    <select className="form-control mb-0" name="purpose_of_ewa_advance" {...AddForm.getFieldProps("purpose_of_ewa_advance")} onBlur={updateEmployeeData} >
                                        <option value=''>--Select--</option>
                                        <option value='freelance writing'>Freelance Writing</option>
                                        <option value='graphic design'>Graphic Design</option>
                                        <option value='handmade crafts'>Handmade Crafts</option>
                                        <option value='tutoring services'>Tutoring Services</option>
                                        <option value='online retail'>Online Retail (e.g., Etsy shop)</option>
                                        <option value='social media management'>Social Media Management</option>
                                        <option value='photography'>Photography</option>
                                        <option value='event planning'>Event Planning</option>
                                        <option value='food delivery service'>Food Delivery Service</option>
                                        <option value='blogging vlogging'>Blogging/Vlogging</option>
                                        <option value='fitness coaching'>Fitness Coaching</option>
                                        <option value='language translation services'>Language Translation Services</option>
                                        <option value='pet sitting dog walking'>Pet Sitting/Dog Walking</option>
                                        <option value='gardening landscaping'>Gardening/Landscaping</option>
                                        <option value='home cleaning services'>Home Cleaning Services</option>
                                        <option value='virtual assistance'>Virtual Assistance</option>
                                        <option value='carpentry woodworking'>Carpentry/Woodworking</option>
                                        <option value='beauty services'>Beauty Services (e.g., makeup artist, hairstylist)</option>
                                        <option value='digital marketing'>Digital Marketing</option>
                                        <option value='app development'>App Development</option>
                                        <option value='dropshipping'>Dropshipping</option>
                                        <option value='affiliate marketing'>Affiliate Marketing</option>
                                        <option value='online courses workshops'>Online Courses/Workshops</option>
                                        <option value='handyman services'>Handyman Services</option>
                                        <option value='tailoring alterations'>Tailoring/Alterations</option>
                                        <option value='food production'>Food Production (e.g., catering, baking)</option>
                                        <option value='fishing'>Fishing</option>
                                        <option value='logistics services'>Logistics Services</option>
                                        <option value='trading'>Trading (e.g., stocks, commodities)</option>
                                        <option value='merchandising'>Merchandising</option>
                                        <option value='accounting services'>Accounting Services</option>
                                        <option value='retail business'>Retail Business</option>
                                        <option value='agriculture farming'>Agriculture/Farming</option>
                                        <option value='tourism services'>Tourism Services (e.g., tour guide)</option>
                                        <option value='real estate services'>Real Estate Services</option>
                                    </select>
                                </div>
                                {AddForm.touched.purpose_of_ewa_advance && Object.keys(AddForm.errors)[0] == 'purpose_of_ewa_advance' && AddForm.errors.purpose_of_ewa_advance ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.purpose_of_ewa_advance}</div> : ''}

                            </div>
                            <div className="form-group col-md-4">
                                <label className="form-label">Monthly Income With This Business
                                    {AddForm.values.monthly_business_income_true ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}
                                </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter Monthly Income With This Business" {...AddForm.getFieldProps("monthly_business_income")} onBlur={updateEmployeeData} />
                                    </div>
                                    {AddForm.touched.monthly_business_income && Object.keys(AddForm.errors)[0] == 'monthly_business_income' && AddForm.errors.monthly_business_income ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.monthly_business_income}</div> : ''}
                                </div>
                            </div>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input" name="is_consent1" value="1"
                                    checked={AddForm.values.is_consent1 == 1}
                                    onChange={(e) => updateEmployeeData(e)} />
                                <span className="custom-control-label">
                                    <p> I agree that the EWA Advance money will be used for the mentioned business purpose only.</p>
                                </span>
                                {AddForm.touched.is_consent1 && Object.keys(AddForm.errors)[0] == 'is_consent1' && AddForm.errors.is_consent1 ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.is_consent1}</div> : ''}
                            </label>
                        </div>

                        <div className="row mb-3">
                            <h4>Consent 2
                                {AddForm.values.is_consent_true ?
                                    <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                    : ''}</h4>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input" name="is_consent" value="1"
                                    checked={AddForm.values.is_consent == 1} onChange={(e) => updateEmployeeData(e)} />
                                <span className="custom-control-label">
                                    <p>1. We hereby consent to the release of credit information held by Merchant Finance Pte Limited to any Credit Reporting Agency, and we also authorize Merchant Finance Pte Limited to inquire and obtain credit information relating to us from any Credit Reporting Agency in relation to our credit facility. This authority is given by us and obtained by Merchant Finance Pte Limited pursuant to Regulation 9(6) of the Fair Reporting Regulation 2016.</p>

                                    <p> 2. We hereby declare that the information provided in this finance application is true and accurate, and Merchant Finance Pte Limited is authorized to conduct any further checks.</p>
                                </span>
                            </label>
                        </div>
                        {AddForm.touched.is_consent && Object.keys(AddForm.errors)[0] == 'is_consent' && AddForm.errors.is_consent ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.is_consent}</div> : ''}


                        <div className="row mb-3">
                            <div className="col-lg-4 col-sm-12">
                                <p className="mb-0">Signature
                                    {props.data && props.data.SIGNATURE_IMAGE.length > 0 ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}</p>
                                <div className="input-group file-browser mb-0 widthHundred digitalSignature">

                                    <input type="file" className="form-control browse-file"
                                        ref={inputFile}
                                        placeholder="Upload your Signature here"
                                        onChangeCapture={(e) => uploadImage(e, 'SIGNATURE')} />
                                    <label className="input-group-text btn btn-primary"> Browse
                                    </label>

                                    {AddForm.touched.SIGNATURE && Object.keys(AddForm.errors)[0] == 'SIGNATURE' && AddForm.errors.SIGNATURE ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.SIGNATURE}</div> : ''}

                                </div>
                                {props.data && props.data.SIGNATURE_IMAGE.length > 0 ? <ul className="imgpreview-newbx">
                                    {props.data.SIGNATURE_IMAGE.map((option, index) => {
                                        return (
                                            <li className="registerboximg" key={index}>
                                                {option?.file_type.toLowerCase() === ".pdf" ? (
                                                    <Link to={option.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "SIGNATURE"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                ) : (
                                                    <Link to={option.doc_file}>
                                                        <img
                                                            src={option.doc_file}
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="..." />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "SIGNATURE"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul> : ""}

                            </div>
                        </div>

                    </div>


                    <input type="submit" name="next" className="next action-button apply-now-btn ml-00" value="Continue" />
                    {/* </form> */}
                </fieldset>


                {/*/////////// Disclaimer End  =========>>>>>>>>>>>*/}



                {/*/////////// Document Start  =========>>>>>>>>>>>*/}


                <fieldset className="mb-3" style={currentStep == 9 ? { display: "block" } : { display: "none" }}>
                    <button type="button" name="previous" className="previous action-button-previous" onClick={() => setCurrentStep(currentStep - 1)} ><i className="fa-solid fa-arrow-left-long fa-fw"></i> Back</button>
                    <h1 className="mb-0 mt-1">Documents</h1>
                    <div className="stepform-newdesign">
                        <div className="row mb-3">
                            <div className="col-lg-6 col-sm-12 mb-3">
                                <p className="mb-0">Two(2) most Recent Payslips <span className='text-danger'>*</span>
                                    {props.data && props.data.PAYSLIPS_IMAGE.length > 0 ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}</p>
                                <div className="input-group file-browser mb-0 widthHundred digitalSignature" > <input type="file" className="form-control browse-file" placeholder="Upload your Pay Slip Here" ref={inputFile1} onChangeCapture={(e) => uploadImage(e, 'PAYSLIPS')} /* accept=".pdf" */ /> <label className="input-group-text btn btn-primary"> Browse </label>

                                </div>
                                {AddForm.touched.PAYSLIPS && Object.keys(AddForm.errors)[0] == 'PAYSLIPS' && AddForm.errors.PAYSLIPS ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.PAYSLIPS}</div> : ''}
                                {props.data && props.data.PAYSLIPS_IMAGE.length > 0 ? (
                                    <ul className="imgpreview-newbx">
                                        {props.data.PAYSLIPS_IMAGE.map((option, index) => {
                                            return (
                                                <li className="registerboximg" key={index}>
                                                    {option?.file_type.toLowerCase() === ".pdf" ? (
                                                        <Link to={option.doc_file} download>
                                                            <img
                                                                src="/assets/img/pdf.jpeg"
                                                                style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                                className="card-img-top m-1"
                                                                alt="PDF"
                                                            />
                                                            <Link to="" className="upload__img-close">
                                                                <i className="fa-solid fa-close fa-fw"
                                                                    onClick={() => { deletedoc(option.id, "PAYSLIPS"); handleReset(); }}></i>
                                                            </Link>
                                                        </Link>
                                                    ) : (
                                                        <Link to={option.doc_file}>
                                                            <img
                                                                src={option.doc_file}
                                                                style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                                className="card-img-top m-1"
                                                                alt="..." />
                                                            <Link to="" className="upload__img-close">
                                                                <i className="fa-solid fa-close fa-fw"
                                                                    onClick={() => { deletedoc(option.id, "PAYSLIPS"); handleReset(); }}></i>
                                                            </Link>
                                                        </Link>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : ""}

                                {/*<ul className="imgpreview-newbx">
                                <li className="registerboximg">
                                  <img src="" alt="" />
                                  <Link to="" className="upload__img-close">
                                    <i className="fa-solid fa-close fa-fw"></i>
                                  </Link>
                                  <p>img preview name here</p>
                                </li>
                              </ul>*/}
                            </div>
                            <div className="col-lg-6 col-sm-12 mb-3">
                                <p className="mb-0">Two(2) Vaild Photo ID's (Optional)
                                    {props.data && props.data.PHOTO_ID_IMAGE.length > 0 ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}</p>
                                <div className="input-group file-browser mb-0 widthHundred digitalSignature">
                                    <input type="file" className="form-control browse-file"
                                        placeholder="Upload your photo Here"
                                        ref={inputFile2}
                                        onChangeCapture={(e) => uploadImage(e, 'PHOTO_ID')} />
                                    <label className="input-group-text btn btn-primary"> Browse
                                        {/* <input type="file" className="file-browserinput" /> */}  </label>
                                </div>
                                {AddForm.touched.PHOTO_ID && Object.keys(AddForm.errors)[0] == 'PHOTO_ID' && AddForm.errors.PHOTO_ID ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.PHOTO_ID}</div> : ''}

                                {props.data && props.data.PHOTO_ID_IMAGE.length > 0 ? <ul className="imgpreview-newbx">

                                    {props.data.PHOTO_ID_IMAGE.map((option, index) => {
                                        return (
                                            <li className="registerboximg" key={index}>
                                                {option?.file_type.toLowerCase() === ".pdf" ? (
                                                    <Link to={option.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "PHOTO_ID"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                ) : (
                                                    <Link to={option.doc_file}>
                                                        <img
                                                            src={option.doc_file}
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="..." />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "PHOTO_ID"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul> : ""}
                            </div>
                            <div className="col-lg-6 col-sm-12 mb-3">
                                <p className="mb-0">Letter of employment confirmation (Optional)
                                    {props.data && props.data.EMPLOYMENT_LETTER_IMAGE.length > 0 ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}</p>
                                <div className="input-group file-browser mb-0 widthHundred digitalSignature">
                                    <input type="file" className="form-control browse-file"
                                        placeholder="Upload your employment letter Here" ref={inputFile3}
                                        onChangeCapture={(e) => uploadImage(e, 'EMPLOYMENT_LETTER')} /* accept='.pdf' */ />
                                    <label className="input-group-text btn btn-primary"> Browse
                                    </label>
                                </div>
                                {AddForm.touched.EMPLOYMENT_LETTER && Object.keys(AddForm.errors)[0] == 'EMPLOYMENT_LETTER' && AddForm.errors.EMPLOYMENT_LETTER ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.EMPLOYMENT_LETTER}</div> : ''}
                                {props.data && props.data.EMPLOYMENT_LETTER_IMAGE.length > 0 ? <ul className="imgpreview-newbx">

                                    {props.data.EMPLOYMENT_LETTER_IMAGE.map((option, index) => {
                                        return (
                                            <li className="registerboximg" key={index}>
                                                {option?.file_type.toLowerCase() === ".pdf" ? (
                                                    <Link to={option.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "EMPLOYMENT_LETTER"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                ) : (
                                                    <Link to={option.doc_file}>
                                                        <img
                                                            src={option.doc_file}
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="..." />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "EMPLOYMENT_LETTER"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul> : ""}
                                {/*<ul className="imgpreview-newbx">
                                <li className="registerboximg">
                                  <img src="" alt="" />
                                  <Link to="" className="upload__img-close">
                                    <i className="fa-solid fa-close fa-fw"></i>
                                  </Link>
                                  <p>img preview name here</p>
                                </li>
                              </ul>*/}
                            </div>
                            <div className="col-lg-6 col-sm-12 mb-3">
                                <p className="mb-0">TIN letter/TIN (Optional) {props.data && props.data.TIN_LETTER_IMAGE.length > 0 ?
                                    <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                    : ''}</p>
                                <div className="input-group file-browser mb-0 widthHundred digitalSignature">
                                    <input type="file" className="form-control browse-file" placeholder="Upload your TIN Here"
                                        onChangeCapture={(e) => uploadImage(e, 'TIN_LETTER')} /* accept='.pdf' */ ref={inputFile4} />
                                    <label className="input-group-text btn btn-primary"> Browse

                                    </label>
                                </div>
                                {AddForm.touched.TIN_LETTER && Object.keys(AddForm.errors)[0] == 'TIN_LETTER' && AddForm.errors.TIN_LETTER ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.TIN_LETTER}</div> : ''}
                                {props.data && props.data.TIN_LETTER_IMAGE.length > 0 ? <ul className="imgpreview-newbx">

                                    {props.data.TIN_LETTER_IMAGE.map((option, index) => {
                                        return (
                                            <li className="registerboximg" key={index}>
                                                {option?.file_type.toLowerCase() === ".pdf" ? (
                                                    <Link to={option.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "TIN_LETTER"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                ) : (
                                                    <Link to={option.doc_file}>
                                                        <img
                                                            src={option.doc_file}
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="..." />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "TIN_LETTER"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul> : ""}

                                {/*<ul className="imgpreview-newbx">
                                <li className="registerboximg">
                                  <img src="" alt="" />
                                  <Link to="" className="upload__img-close">
                                    <i className="fa-solid fa-close fa-fw"></i>
                                  </Link>
                                  <p>img preview name here</p>
                                </li>
                              </ul>*/}
                            </div>
                            <div className="col-lg-6 col-sm-12 mb-3">
                                <p className="mb-0 fs-14">If any, 3 month statement of other Loan or hire purchase commitment (Optional)
                                    {props.data && props.data.LOAN_STATEMENTS_IMAGE.length > 0 ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}</p>
                                <div className="input-group file-browser mb-0 widthHundred digitalSignature">
                                    <input type="file" className="form-control browse-file"
                                        placeholder="Upload your statement Here"
                                        onChangeCapture={(e) => uploadImage(e, 'LOAN_STATEMENTS')} /* accept='.pdf' */ ref={inputFile5} />
                                    <label className="input-group-text btn btn-primary"> Browse
                                        {/* <input type="file" className="file-browserinput" /> */}
                                    </label>
                                </div>
                                {AddForm.touched.LOAN_STATEMENTS && Object.keys(AddForm.errors)[0] == 'LOAN_STATEMENTS' && AddForm.errors.LOAN_STATEMENTS ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.LOAN_STATEMENTS}</div> : ''}
                                {props.data && props.data.LOAN_STATEMENTS_IMAGE.length > 0 ? <ul className="imgpreview-newbx">

                                    {props.data.LOAN_STATEMENTS_IMAGE.map((option, index) => {
                                        return (
                                            <li className="registerboximg" key={index}>
                                                {option?.file_type.toLowerCase() === ".pdf" ? (
                                                    <Link to={option.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "LOAN_STATEMENTS"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                ) : (
                                                    <Link to={option.doc_file}>
                                                        <img
                                                            src={option.doc_file}
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="..." />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "LOAN_STATEMENTS"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul> : ""}
                            </div>
                            <div className="col-lg-6 col-sm-12 mb-3">
                                <p className="mb-0">Bank statements (at-least 3 months)<span className='text-danger'>*</span>
                                    {props.data && props.data.BANK_STATEMENTS_IMAGE.length > 0 ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}</p>
                                <div className="input-group file-browser mb-0 widthHundred digitalSignature">
                                    <input type="file" className="form-control browse-file"
                                        placeholder="Upload your bank statement Here"
                                        onChangeCapture={(e) => uploadImage(e, 'BANK_STATEMENTS')} ref={inputFile6}
                                        /* accept='.pdf' */ />
                                    <label className="input-group-text btn btn-primary"> Browse

                                    </label>
                                </div>
                                {AddForm.touched.BANK_STATEMENTS && Object.keys(AddForm.errors)[0] == 'BANK_STATEMENTS' && AddForm.errors.BANK_STATEMENTS ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.BANK_STATEMENTS}</div> : ''}
                                {props.data && props.data.BANK_STATEMENTS_IMAGE.length > 0 ? <ul className="imgpreview-newbx">

                                    {props.data.BANK_STATEMENTS_IMAGE.map((option, index) => {
                                        return (
                                            <li className="registerboximg" key={index}>
                                                {option?.file_type.toLowerCase() === ".pdf" ? (
                                                    <Link to={option.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "BANK_STATEMENTS"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                ) : (
                                                    <Link to={option.doc_file}>
                                                        <img
                                                            src={option.doc_file}
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="..." />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "BANK_STATEMENTS"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul> : ""}
                            </div>
                            <div className="col-lg-6 col-sm-12 mb-3">
                                <p className="mb-0">Income (Optional)
                                    {props.data && props.data.INCOME_IMAGE.length > 0 ?
                                        <i className="fa-solid fa-check fa-fw checkgreen"></i>
                                        : ''}</p>
                                <div className="input-group file-browser mb-0 widthHundred digitalSignature">
                                    <input type="file" className="form-control browse-file" ref={inputFile7}
                                        placeholder="Upload your bank statement Here"
                                        onChangeCapture={(e) => { uploadImage(e, 'INCOME') }} /* accept='.pdf' */ />
                                    <label className="input-group-text btn btn-primary"> Browse

                                    </label>
                                </div>
                                {AddForm.touched.INCOME && Object.keys(AddForm.errors)[0] == 'INCOME' && AddForm.errors.INCOME ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.INCOME}</div> : ''}
                                {props.data && props.data.INCOME_IMAGE.length > 0 ? <ul className="imgpreview-newbx">

                                    {props.data.INCOME_IMAGE.map((option, index) => {
                                        return (
                                            <li className="registerboximg" key={index}>
                                                {option?.file_type.toLowerCase() === ".pdf" ? (
                                                    <Link to={option.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "INCOME"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                ) : (
                                                    <Link to={option.doc_file}>
                                                        <img
                                                            src={option.doc_file}
                                                            style={{ width: '80px', height: '80px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="..." />
                                                        <Link to="" className="upload__img-close">
                                                            <i className="fa-solid fa-close fa-fw"
                                                                onClick={() => { deletedoc(option.id, "INCOME"); handleReset(); }}></i>
                                                        </Link>
                                                    </Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul> : ""}
                            </div>
                        </div>
                    </div>

                    <input type="submit" name="next" className="next action-button apply-now-btn ml-00" value="Submit" />
                </fieldset>


                {/*/////////// Document End  =========>>>>>>>>>>>*/}
            </form>
        </div>
    )
}